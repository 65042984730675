<template>
  <v-container class="mt-5">
    <v-row justify="center">
      <v-col cols="12">
        <v-row>
          <v-col>
            <h1>Sportarten</h1>
          </v-col>
          <v-col cols="auto">
            <v-btn large rounded color="primary" @click="create">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Sportart erstellen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          filled
          rounded
          label="Suche"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="sportarten.data"
          item-key="name"
          show-select
          class="elevation-5 rounded-xl"
          fixed-header
          :search="search"
          locale="de-DE"
          :footer-props="{
            itemsPerPageText: 'Zeilen pro Seite:',
          }"
        >
          <template v-slot:no-data>
            Es wurden keine Sportarten gefunden.
          </template>
          <template v-slot:item.name="{ index, item }">
            <v-btn text rounded :to="`/admin/sportarten/${item.id}`">
              <v-icon class="mr-2" v-if="!item.iconset || item.iconset == 'Material'">{{ item.icon }}</v-icon>
              <font-awesome-icon :icon="item.icon" size="xl" class="mr-2" v-if="item.iconset" />
              {{ item.name }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Verein from './sportart.vue'
import {
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
} from 'firebase/firestore'
import router from '../../../router'

export default {
  name: 'Verein',
  data() {
    return {
      selected: [],
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
    }),
  },
  components: {
    Verein,
  },
  methods: {
    async create() {
      const docRef = await addDoc(collection(this.db, 'Sportarten'), {
        name: '',
        icon: '',
        created_at: serverTimestamp(),
        created: false,
      })
      router.push('/admin/sportarten/' + docRef.id)
    },
  },
}
</script>
